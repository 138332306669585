<template>
  <div style="background-color: #f4f4f4;height: 100%">
    <el-page-header style="margin-left: 37px;padding-top: 21px" @back="goBack"></el-page-header>

    <!-- <UPimg  v-on:uploadSuccess="uploadSuccess" ref="upway"></UPimg> -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button @click="queding" style="float: right" type="primary">确定</el-button>
      </div>
      <div class="text item">
        <div class="tstitle">*号为必填项</div>


        <el-form ref="ruleForm"  label-width="100px" class="demo-ruleForm">
          <el-form-item style="font-weight: bold" label="型号" required>
            <el-input  style="width: 220px" v-model="model"></el-input>
          </el-form-item>
          <el-form-item style="font-weight: bold" label="单片规格" required>
            <el-input  style="width: 120px" placeholder="素材宽度" v-model="specificationwidth"></el-input> *
            <el-input  style="width: 120px" placeholder="素材高度" v-model="specificationheight"></el-input>
          </el-form-item>

          <el-form-item style="font-weight: bold" label="系列">
              <el-select v-model="type_id" placeholder="请选择">
                <el-option
                  v-for="item in type_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item style="font-weight: bold" label="品类">
                <el-radio-group v-model="category_id">
                  <el-radio :label="1">瓷砖</el-radio>
                  <el-radio :label="2">岩板</el-radio>
                </el-radio-group>
          </el-form-item>
          <el-form-item style="font-weight: bold" label="类型">
                <el-radio-group v-model="type_p">
                  <el-radio :label="1">单片</el-radio>
                  <el-radio :label="2">连纹</el-radio>
                </el-radio-group>
          </el-form-item>
          <el-form-item style="font-weight: bold" label="铺贴方式" v-if="type_p == 2">
                <el-radio-group v-model="type_pave" class="al-wrap">
                  <el-radio :label="1">
                    <div>单排连纹</div>
                    <img src="@/assets/images/dan.png" height="60" alt="">
                  </el-radio>
                  <el-radio :label="2">
                    <div>双排连纹</div>
                    <img src="@/assets/images/shuang.png" height="60" alt="">
                  </el-radio>
                </el-radio-group>
          </el-form-item>
          <el-form-item style="font-weight: bold" label="素材上传" required>
              <div class="upload-wrap">
                  <aliyun-upload 
                    :limitSize="8" :limit="display" :isPanorama="true" :isShopTip="false" :multiple="multiple"
                    :btnDisplay="image.length < display" listType="picture-card" :valueData="specificationheight"
                    v-model="image">
                  </aliyun-upload>
                  <draggable class="box fileList" v-model="image" 
                    v-if="image.length>0 && type_pave == 1">
                    <div v-for="(item,index) in image" :key="index" class="image-wrap"
                      :style="{height:(specificationheight/specificationwidth*150)+'px'}">
                      <div @click="delfileList(index)" class="delFile">x</div>
                      <el-image class="pic" ref="imgSize"
                          :preview-src-list="image" :src="item"/>
                      <el-tooltip class="item" effect="dark" content="旋转" placement="bottom">
                        <div class="iconfont" @click="clickRotate(index)">
                          <i class="el-icon-refresh-right"></i>
                        </div>
                      </el-tooltip>
                    </div>
                  </draggable>

                  <draggable class="box active-box" v-model="image" :style="{height: (specificationheight/specificationwidth*150*2)+'px'}"
                  v-if="image.length>0  && type_pave == 2">
                    <div class="image-wrap" v-for="(item,index) in image" :key="index" 
                      :style="{height:(specificationheight/specificationwidth*150)+'px'}">
                      <div @click="delfileList(index)" class="delFile">x</div>
                      <el-image class="pic" ref="imgSize"
                          :preview-src-list="image" :src="item"/>
                      <el-tooltip class="item" effect="dark" content="旋转" placement="bottom">
                        <div class="iconfont" @click="clickRotate(index)">
                          <i class="el-icon-refresh-right"></i>
                        </div>
                      </el-tooltip>
                    </div>
                  </draggable>
              </div>
          </el-form-item>

        </el-form>

        <!-- <el-form required>
          <el-form-item style="font-weight: bold" label="素材上传"/>
        </el-form>
        <div class="el-upload__tip">只能上传jpg/png文件，且不超过8MB</div>

        <el-upload
          multiple
          :limit=1
          :on-exceed="errorfilelst"
          :show-file-list="false"
          name="pice"
          action
          :http-request="picUpload"
          :before-upload="beforeupload"
          :file-list="fileList"
          list-type="picture">
          <el-button style="width: 100px;height: 38px" size="small" icon="el-icon-plus" type="primary"></el-button>
        </el-upload>

        <draggable v-model="bigfileList" class="box1">
          <div v-for="(item,i2) of bigfileList" :key="i2" class="fileList1-box">
            <div @click="delfileList1(i2)" class="delFile">x</div>
            <el-image
              fit="contain"
              class="pic"
              :src="item"
              :preview-src-list="bigfileList"
            />
          </div>
        </draggable> -->

      </div>
    </el-card>

  </div>
</template>

<script>
  import common from "@/utils/common";
  import draggable from "vuedraggable"
//   import {get_sucai_edit,get_sucai_info} from "../../shopApi/shopapi";
//   import UPimg from "../uploading/UPimg";
  import AliyunUpload from "@/components/upload/AliyunUpload.vue";
  export default {
    components: {
    //   UPimg,
      draggable,
      AliyunUpload
    },
    name: "changedPanoramaMaterial",
    data(){
      return {
        model:'',
        specificationwidth: 0,
        specificationheight: 0,
        fileList: [],
        bigfileList:[],
        uploadOss:'',
        id:'',
        type_id:null,
        type_list:[],
        type_p: 1,
        type_pave: 1,
        display: 1,
        image: [],
        multiple: true,
        category_id: 1,
        height: 0
      };
    },
    created() {
      this.uploadOss=common.apiServer+"/api/Ydapi/uploadOss"
      this.id=this.$route.query.sucaiid
      common.connect('/distributorpcv1/Scene/get_sucai_info', {id:this.id}, (res)=>{
        this.model=res.data.productNum
        this.specificationwidth=res.data.productWidth
        this.specificationheight=res.data.productHeight
        if(res.data.type_id){
          this.type_id = res.data.type_id;
        }
        
        this.bigfileList.push(res.data.productFileId)
        let params={
            url:this.bigfileList[0].productFileId
        }
        this.fileList.push(params)
        this.type_p = res.data.type_p;
        this.category_id = res.data.category_id;
        this.type_pave = res.data.type_pave;
        if(res.data.type_p == 1){
          this.image[0] = res.data.productFileId
        }else{
            let img = res.data.productFileIds.split(",");
            this.$nextTick(()=>{
              this.image = img
              this.display = 6
            })
            
        }
      })
    //   get_sucai_info({id:this.id}).then(res=>{
    //       if(res.data.code===1){
    //           this.model=res.data.data.productNum
    //           this.specificationwidth=res.data.data.productWidth
    //           this.specificationheight=res.data.data.productHeight
    //           if(res.data.data.type_id){
    //             this.type_id = res.data.data.type_id;
    //           }
              
    //           this.bigfileList.push(res.data.data.productFileId)
    //           let params={
    //               url:this.bigfileList[0].productFileId
    //           }
    //           this.fileList.push(params)
    //           this.type_p = res.data.data.type_p;
    //           this.category_id = res.data.data.category_id;
    //           this.type_pave = res.data.data.type_pave;
    //           if(res.data.data.type_p == 1){
    //             this.image[0] = res.data.data.productFileId
    //           }else{
    //               let img = res.data.data.productFileIds.split(",");
    //               this.$nextTick(()=>{
    //                 this.image = img
    //                 this.display = 6
    //               })
                  
    //           }
    //       }
    //   });
      common.connect("/distributorpcv1/Scene/list_sucai_kind",{name},(res)=>{
        this.type_list = res.data;
      });
      
    },
    watch:{
        type_p(newValue,oldValue){
          if(newValue == 2){
            this.display = 6;
            this.image = []
            if(this.image <= 6){
              this.multiple = true
            }else{
              this.multiple = false
            }
          }else{
            this.display = 1,
            this.image = []
          }
        }
    },
    methods: {
     
      // 旋转
      clickRotate(index){
        if(this.specificationwidth == this.specificationheight){
          if(this.image[index].indexOf("r90") != -1){
            let img = this.image[index].split('?')[0];
            this.$set(this.image,index,img+ '?x-oss-process=style/r180')
          }else if(this.image[index].indexOf("r180") != -1){
            let img = this.image[index].split('?')[0];
            this.$set(this.image,index,img+ '?x-oss-process=style/r270')
          }else if(this.image[index].indexOf("r270") != -1){
            let img = this.image[index].split('?')[0]
            this.$set(this.image,index,img)
          }else{
            this.$set(this.image,index,this.image[index]+ '?x-oss-process=style/r90')
          }
        }else{
          if(this.image[index].indexOf("?") != -1){
            let img = this.image[index].split('?')[0]
            this.$set(this.image,index,img)
          }else{
            this.$set(this.image,index,this.image[index]+ '?x-oss-process=style/r180')
          }
        }
      },
      delfileList(index){
        this.image.splice(index,1)
      },
      goBack(){
        this.$router.back()
      },
      queding(){
        if(this.model===''){
          this.$message.error('素材型号不能为空');
          return
        }
        if(this.image.length<=0){
          this.$message.error('素材图片不能为空');
          return
        }
        if(this.specificationwidth===''){
          this.$message.error('素材宽度不能为空');
          return
        }
        if(this.specificationheight===''){
          this.$message.error('素材高度不能为空');
          return
        }
        let params={
          id:this.id,
          name:this.model,
          url:this.image[0],
          width:this.specificationwidth,
          height:this.specificationheight,
          productFileIds: this.image,
          type_pave: this.type_pave,
          type_p: this.type_p,
          category_id: this.category_id
        }
        if(this.type_id){
          params.type_id = this.type_id;
        }

        common.connect('/distributorpcv1/Scene/get_sucai_edit', params, ()=>{
            this.$message({
                showClose: true,
                message: "修改成功",
                type: 'success'
              });
              this.$router.back()
        })

        // get_sucai_edit(params).then(res=>{
        //     if(res.data.code===1){
        //       this.$message({
        //         showClose: true,
        //         message: "修改成功",
        //         type: 'success'
        //       });
        //       this.$router.push("/ShopProduct/panoramaproduct")
        //     }
        // })
      },
      delfileList1(i){
        this.fileList.splice(i, 1)
        this.bigfileList.splice(i, 1)
      },
      errorfilelst(file, fileList) {
        this.$message.error('上传素材图片个数为1张');
      },
      uploadsucceed(fileList) {
        this.bigfileList.push(fileList.data)
        this.fileList.length=this.bigfileList.length
      },
      async picUpload(file) {
        this.loading=true
        await this.$refs.upway.picUpload(file)
      },
      uploadSuccess(uploadSuccess) {
        // HomeuploadSuccess就是子组件传过来的值
        this.bigfileList.push(uploadSuccess)
        this.fileList.length=this.bigfileList.length
      },
      beforeupload(file){
        const isLt2M = file.size / 1024 / 1024 < 15;
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';

        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 15MB!');
          return isLt2M;
        }

        if (!isJPG && !isPNG) {
          this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
          return false
        }
      },
    }
  }
</script>

<style scoped lang="scss">
.al-wrap{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .el-radio{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .el-radio__label{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.upload-wrap{
  display: flex;
  flex-wrap: wrap;
    .active-box{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .fileList{
      display: flex;
      flex-wrap: wrap;
    }
    .box{
        margin-right: 20px;
        max-width: calc(100% - 100px);
        .image-wrap{
          position: relative;
          // width: 140px;
          overflow: hidden;
          .delFile{
              position: absolute;
              top: 5px;
              left: 5px;
              cursor: pointer;
              width: 20px;
              height: 20px;
              border-radius: 100%;
              border: 1px #ddd solid;
              background: rgba(100, 100, 100, 0.5);
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 99;
          }
          .iconfont{
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%);
            background: rgba(0, 0, 0, 0.2);
            border-radius: 100%;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            .el-icon-refresh-right{
              color: #fff;
            }
          }
          .pic{
            width: 100%;
            height: 100%;
            overflow: hidden;
            vertical-align: bottom;
          }
        }
    }
    .leright{
        padding: 15px 0;
    }
}
  .pic .el-image-viewer__close{
    top: 100px;
    right: 200px;
    width: 40px;
    height: 40px;
    font-size: 40px;
  }
</style>
<style scoped>
  .title{
    padding-top: 17px;
    margin-left: 39px;
    font-weight: bold;
    color: rgba(57, 61, 73, 100);
    font-size: 16px;
    text-align: left;
    font-family: SourceHanSansSC-bold;
  }
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    margin: 16px 39px;
    width: 90%;
    line-height: 20px;
    border-radius: 4px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.07);
    font-family: Roboto;
    border: 1px solid rgba(255, 255, 255, 100);
  }
  .tstitle{
    color: rgba(255, 21, 21, 100);
    font-size: 14px;
    text-align: left;
    font-family: SourceHanSansSC-regular;
  }
  .demo-ruleForm{
    /* margin-left: -100px; */
    margin-top: 16px;
    /* width: 800px; */
    /* display: flex; */
    /* flex-wrap: wrap; */
  }
  .box1{
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
  }
  .fileList1-box{
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 110px;
    height: 110px;
    position: relative;
  }
  .pic{
    width: 110px;
    height: 110px;
  }
  .delFile{
    cursor: pointer;
    z-index: 992;
    position: absolute;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    width: 20px;
    height: 20px;
    right: 0px;
    top: 0px;
    background-color: #f4f4f4;
    border-radius: 15px;
  }
  .el-upload__tip{
    cursor: default;
    font-weight: normal;
    width: 100%;
    
    margin-bottom: 20px;
    font-size: 12px;
    color: #606266;
  }
</style>
